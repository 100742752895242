import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3b3bb0ca"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "reputation"
};
const _hoisted_2 = {
  key: 0,
  class: "list"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "company"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "text"
};
const _hoisted_7 = {
  class: "progress-box"
};
const _hoisted_8 = {
  class: "text"
};
const _hoisted_9 = {
  class: "lv"
};
const _hoisted_10 = {
  class: "num"
};
import { onMounted, ref, reactive } from 'vue';
import { getReputationAll, getUserReputationList } from '../../api/reputation';
import { userInfo as localUserInfo } from '../../utils/globalData';
import { useRouter } from 'vue-router';
import useMineStore from '../../store/mine';
import Prompt from '../../component/Prompt';
export default {
  __name: 'index',
  setup(__props) {
    /**
     * 1.通过用户guid获取相关厂商声望信息
     * 2.通过handle.list()方法处理数据
     *      字段说明:
     *          user_value:用户已有该厂商的声望值
     *          user_value_id:用于查询记录的value_id
     *          value:初始等级升级所需要的声望值
     *          value_grade:用户当前拥有的声望信息
     *          value_grade.value:用户下一级需要的声望值
     *          type:
     *              'reputation':厂商声望
     *              'experience':厂商经验
     *          level:用户在当前厂商的等级
     *          obj_info:厂商信息
     *          obj_id:厂商guid
     *
     * 3.通过点击事件,跳转到厂商声望详情页
     */
    const router = useRouter();
    const reputationList = ref([]);
    const store = useMineStore();
    const handle = reactive({
      reputation: data => {
        data.forEach(item => {
          item.companyLogo = item.obj_info.images.cover; // 厂商logo
          item.companyName = item.obj_info.title; // 厂商名称
          item.totalReputation = item.user_value | 0; // 用户当前等级的声望

          if (!item.value_grade) {
            item.levelSlug = 1; // 等级
            item.upLevelReputation = 100; // 用户升级需要的声望
          } else {
            item.levelSlug = item.value_grade.level; // 等级
            item.upLevelReputation = item.value_grade.value; // 用户升级需要的声望
          }

          if (item.totalReputation === 0) {
            item.progress = '0%'; //进度条
          } else {
            item.progress = item.totalReputation / item.upLevelReputation * 100 + '%'; //进度条
          }
        });
        // return data.slice(0, 3);
        return data;
      }
    });
    const go = reactive({
      reputationDetail: item => {
        router.push({
          path: '/reputationDetail',
          query: {
            value_id: item.user_value_id
          }
        });
      }
    });
    onMounted(() => {
      if (!localUserInfo.guid) {
        const data = {
          type: 'reputation',
          level: 1
        };
        getReputationAll(data).then(res => {
          const response = res;
          reputationList.value = handle.reputation(response);
        });
        return;
      }
      const data = {
        type: 'reputation',
        user_id: localUserInfo.guid
      };
      getUserReputationList(data).then(res => {
        const response = res;
        reputationList.value = handle.reputation(response);
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Prompt), {
        msg: "品牌声望有什么用，怎么获取？"
      }), _unref(store).userInfo.guid ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reputationList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index,
          onClick: $event => go.reputationDetail(item)
        }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
          class: "logo",
          src: item.companyLogo,
          alt: ""
        }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, _toDisplayString(item.companyName), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
          class: "progress",
          style: _normalizeStyle({
            '--progress': item.progress
          })
        }, null, 4), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, "LV." + _toDisplayString(item.levelSlug), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(item.totalReputation) + "/" + _toDisplayString(item.upLevelReputation), 1)])])], 8, _hoisted_3);
      }), 128))])) : _createCommentVNode("", true)]);
    };
  }
};